<template lang="pug">
header.header
  .header__container
    //- Logo
    .header__logo
      NuxtLinkLocale(
        to="/"
        alt="Portfolio Exchange")
        img(
          src="/images/portfolio_logo.svg"
          alt="Portfolio Exchange")

    //- Navigation
    .header__navigation(:class="{ 'open': navOpen }")
      nav.header__nav(@click="toggleNav")
        .header__nav__item
          NuxtLinkLocale(to="/") {{ $t('pages.home.title') }}
        .header__nav__item
          NuxtLinkLocale(to="/portfolio-issuers") {{ $t('pages.issuers.title') }}

          .header__nav__dropdown
            NuxtLinkLocale(to="/portfolio-issuers/#reits") {{ $t('pages.issuers.sections.reits') }}
            NuxtLinkLocale(to="/portfolio-issuers/#project") {{ $t('pages.issuers.sections.project') }}
            NuxtLinkLocale(to="/portfolio-issuers/#mid") {{ $t('pages.issuers.sections.mid') }}
            NuxtLinkLocale(to="/portfolio-issuers/#crowfounding") {{ $t('pages.issuers.sections.crowfounding') }}
            NuxtLinkLocale(to="/portfolio-issuers/#venture") {{ $t('pages.issuers.sections.venture') }}
            NuxtLinkLocale(to="/portfolio-issuers/#vehicles") {{ $t('pages.issuers.sections.vehicles') }}
            NuxtLinkLocale(to="/portfolio-issuers/#bonds") {{ $t('pages.issuers.sections.bonds') }}
            NuxtLinkLocale(to="/portfolio-issuers/#money") {{ $t('pages.issuers.sections.money') }}

        .header__nav__item
          NuxtLinkLocale(to="/portfolio-fees") {{ $t('pages.fees.title') }}
        .header__nav__item
          NuxtLinkLocale(to="/portfolio-investors") {{ $t('pages.investors.title') }}

          .header__nav__dropdown
            NuxtLinkLocale(to="/portfolio-investors/#ecps") {{ $t('pages.investors.sections.ecps') }}
            NuxtLinkLocale(to="/portfolio-investors/#professional") {{ $t('pages.investors.sections.professional') }}
            NuxtLinkLocale(to="/portfolio-investors/#retail") {{ $t('pages.investors.sections.retail') }}

        .header__nav__item
          NuxtLinkLocale(to="/portfolio-partners") {{ $t('pages.partners.title') }}
        .header__nav__item
          NuxtLinkLocale(to="/portfolio-members") {{ $t('pages.members.title') }}
        .header__nav__item
          NuxtLinkLocale(to="/portfolio-about") {{ $t('pages.about.title') }}

          .header__nav__dropdown
            NuxtLinkLocale(to="/portfolio-about/#infogob") {{ $t('pages.about.sections.infogob') }}
            NuxtLinkLocale(to="/portfolio-about/#infomerc") {{ $t('pages.about.sections.infomerc') }}
            NuxtLinkLocale(to="/portfolio-about/#infoaten") {{ $t('pages.about.sections.infoaten') }}
            NuxtLinkLocale(to="/portfolio-about/#shareholders") {{ $t('pages.about.sections.shareholders') }}

        //- .header__nav__item
        //-   NuxtLinkLocale(to="/portfolio-news") {{ $t('pages.news.title') }}

        .header__nav__item.header__nav__item--locale
          template(
            v-for="localeItem in locales"
            :key="localeItem.code")
              a(
                :class="{ 'locale--active': locale === localeItem.code }"
                @click="switchLocalePath(localeItem.code)") {{ $t(`locales.${localeItem.code}`) }}

    .header__actions
      //- Language switcher
      .header__nav__item.header__nav__item--locale
        .locale--icon
          svg(
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor")
            path(
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418")

        .header__nav__dropdown.header__nav__dropdown--left
          template(
            v-for="localeItem in locales"
            :key="localeItem.code")
            a(
              :class="{ 'locale--active': locale === localeItem.code }"
              @click="switchLocalePath(localeItem.code)") {{ $t(`locales.${localeItem.code}`) }}

      //- Market button
      NuxtLink.button(
        to="https://app.portfolio.exchange/public/markets"
        target="_blank"
        external) {{ $t('pages.market.title') }}

      //- Hamburger button
      HamburgerButton.hamburgerButton(
        :is-active="navOpen"
        @click="toggleNav")
</template>

<script setup lang="ts">
const route = useRoute()
const { locale, defaultLocale, locales } = useI18n()
const nuxtApp = useNuxtApp()
const i18n = nuxtApp.$i18n
const cookieLocale = useCookie('locale')

const navOpen = ref(false)
const mediaSize = ref<'desktop' | 'mobile'>('desktop')
const PORTRAIT_SIZE = 970

async function switchLocalePath(newLocale: string) {
  // Set the new locale
  i18n.setLocale(newLocale)
  cookieLocale.value = newLocale

  // Redirect to the same page with the new locale
  const newPath = useLocalizePath(route.fullPath, newLocale, defaultLocale, locales.value)
  await navigateTo({ path: newPath })
}

function toggleNav() {
  navOpen.value = !navOpen.value;
}

function handleResize() {
  mediaSize.value = window.innerWidth > PORTRAIT_SIZE ? 'desktop' : 'mobile';
}

watch(mediaSize, (newValue, oldValue) => {
  if (newValue === oldValue) return;

  if (newValue === 'desktop') {
    navOpen.value = true;
  } else {
    navOpen.value = false;
  }
});

onBeforeMount(() => {
  handleResize();
});

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.header__container {
  padding: simple();
  justify-content: space-between;
  align-items: center;
  display: flex;
  max-width: $page-width;
  margin: 0 auto;
  height: $header-height-landscape;
}

.header__logo {
  display: flex;
  margin: half('-');
  padding: half();
  position: relative;
  z-index: 1;

  a {
    background-color: transparent !important;
  }

  img {
    width: auto;
    height: 2.5rem;
  }
}

.header__navigation {
  margin: 0 auto;
}

.header__nav {
  display: flex;
  column-gap: quarter();
  flex-grow: 1;

  .header__nav__item--locale {
    display: none;
  }
}

.header__nav__item {
  position: relative;

  >a.router-link-active {
    background-color: color(background);
  }

  a {
    color: color(gray1);
    font-weight: font-weight(semibold);
    font-size: font-size(small1);
    padding: quarter() half();
    border-radius: $border-radius-small;

    &:hover {
      text-decoration: none;
    }
  }
}

.header__nav__item--locale {
  .header__nav__dropdown {
    width: inherit;
  }

  .locale--icon {
    display: flex;

    svg {
      stroke: color(gray3);
      width: 24px;
    }
  }

  .locale--active {
    background-color: color(background);
    color: color(brand);
  }
}

.header__nav__dropdown {
  visibility: hidden;
  padding: half();
  background-color: color(white);
  border-radius: $border-radius-big;
  overflow: hidden;
  width: 100vw;
  max-width: 28rem;
  margin-top: half();
  z-index: 10;
  top: 120%;
  left: -2rem;
  position: absolute;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border: 1px solid color(gray8);
  opacity: 0;
  transition: all 0.4s;

  a {
    display: block;
    padding: half() simple();
    transition: all 0.2s;
    border-radius: $border-radius;

    &:hover {
      background-color: color(background);
    }
  }
}

.header__nav__dropdown--left {
  left: auto;
  right: -2rem;
}

.header__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: half();
}

.button {
  height: inherit;
  padding: half() simple();
  font-weight: font-weight(semibold);
  font-size: font-size(small1);
  border-radius: $border-radius-small;
}

@include media(touch) {
  .header__container {
    flex-wrap: wrap;
    padding: half();
    justify-content: center;
    column-gap: half();
    row-gap: half();
    height: $header-height-touch;
  }

  .header__logo {
    width: 100vw;
    justify-content: center;
  }

  .header__navigation {
    margin: 0;
  }

  .header__nav {
    column-gap: 0;
  }

  .header__nav__item {
    a {
      font-size: font-size(base);
    }
  }

  .button {
    padding: half();
    font-size: font-size(base);
  }
}

@include media(landscape) {
  .header__nav__item {
    &:hover {
      .header__nav__dropdown {
        visibility: visible;
        top: 100%;
        opacity: 1;
      }

      >a {
        color: color(brand)
      }
    }

    &:has(.header__nav__dropdown) {

      >a,
      .locale--icon {
        &:after {
          content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="hsl(194, 15%, 36%)" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>');
          display: inline-block;
          width: half();
          margin-left: 0.2rem;
          stroke: color(gray3);
          stroke-width: 2;
        }
      }
    }
  }
}

@include media(portrait) {
  .header__container {
    flex-wrap: inherit;
    justify-content: space-between;
    height: $header-height-portrait;
  }

  .header__logo {
    width: auto;

    img {
      height: 2rem;
    }
  }

  .header__navigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: color(white);
    padding-top: quadruple();
    transform: translateY(-100%);
    transition: transform 0.5s ease;

    &.open {
      transform: translateY(0);
    }
  }

  .header__nav {
    flex-direction: column;
    align-items: center;
    row-gap: simple();

    .header__nav__item--locale {
      display: block;
    }
  }

  .header__nav__item {
    >a.router-link-active {
      color: color(brand);
    }

    a {
      padding: half() simple();
      font-size: font-size(h5);
    }
  }

  .header__actions {
    .header__nav__item--locale {
      display: none;
    }
  }

  .header__nav__item--locale {
    margin-top: 0;
    padding-top: size(1.5);
    border-top: 1px solid color(gray8);
  }

  .hamburgerButton {
    display: flex;
  }
}
</style>
